<script>
  // import Promo from "./Promo.svelte";
  // import { fly, scale } from "svelte/transition";
  import Listing from "./Listing.svelte";
  import EditListing from "./EditListing.svelte";
  import { ORDER_BY_DIRECTION } from "./constants/orderByDirection";
  import { ORDER_BY_FIELD } from "./constants/orderByField";
  import { scrollToHero } from "./utils/scrollToHero";

  export let firebase;

  let currentOrderByField = ORDER_BY_FIELD.createdOn;
  let currentOrderByDirection = ORDER_BY_DIRECTION.descending;

  let userLoggedIn = false;
  let allListings = [];

  let hashtagFilter = "";
  let isLoading = true;

  const getNextOrderByDirection = () => {
    if (currentOrderByDirection === ORDER_BY_DIRECTION.descending) {
      return ORDER_BY_DIRECTION.ascending;
    }
    return ORDER_BY_DIRECTION.descending;
  };

  if (firebase) {
    const readPosts = (
      orderByField = ORDER_BY_FIELD.createdOn,
      orderByDirection = ORDER_BY_DIRECTION.descending
    ) => {
      // read posts
      isLoading = true;
      const db = firebase.firestore();

      let postsRef = db.collection("posts");
      if (hashtagFilter) {
        postsRef = postsRef.where("tags", "array-contains", hashtagFilter);
      }

      postsRef
        .orderBy(orderByField, orderByDirection)
        .limit(1000)
        .onSnapshot(querySnapshot => {
          allListings = [];
          querySnapshot.forEach(doc => {
            const {
              title,
              images,
              imageThumbnails,
              description,
              phone,
              price,
              location,
              tags = [],
              createdOn: createdOnTimestamp
            } = doc.data();

            const id = doc.id;
            const createdOn = new Date(createdOnTimestamp.seconds * 1000);

            allListings.push({
              id,
              title,
              price,
              images,
              imageThumbnails,
              description,
              phone,
              location,
              tags,
              createdOn
            });

            isLoading = false;
          });
        }, console.error);
    };

    // firebase user state change event
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        userLoggedIn = true;
        readPosts(currentOrderByField, currentOrderByDirection);
      } else {
        // User is signed out
        userLoggedIn = false;
      }
    });

    window.addEventListener("adeka-sort-change", e => {
      const { orderByField, orderByDirection } = e.detail;

      if (orderByField && currentOrderByField !== orderByField) {
        // Re-fetch the list if the order is different
        currentOrderByField = orderByField;
        currentOrderByDirection = ORDER_BY_DIRECTION.descending;
        readPosts(orderByField, currentOrderByDirection);
      } else {
        // Reverse the list if only the direction is changing
        currentOrderByDirection = getNextOrderByDirection();
        readPosts(orderByField, currentOrderByDirection);
      }
    });

    window.addEventListener("adeka-hashtag-change", e => {
      const { hashtag } = e.detail || {};
      hashtagFilter = hashtag;
      currentOrderByField = ORDER_BY_FIELD.createdOn;
      currentOrderByDirection = ORDER_BY_DIRECTION.descending;
      readPosts();
      scrollToHero();
    });
  }

  const handleClearFilters = () => {
    const hashtagChangeEvent = new CustomEvent("adeka-hashtag-change");
    window.dispatchEvent(hashtagChangeEvent);
  };
</script>

<style type="text/scss">.listings-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow: hidden; }

.hashtag-notification {
  width: 100%;
  max-width: 38rem;
  margin: 1rem auto 0; }

.edit-listing-w {
  margin-top: 1rem; }
</style>

<div class="listings-wrapper">
  {#if hashtagFilter}
    <div class="card-notification hashtag-notification">
      <span class="msg">Showing filtered ads for #{hashtagFilter}</span>
      <button class="btn" on:click={handleClearFilters}>Clear filter</button>
    </div>
  {/if}
  <div class:isLoading class="loader" />
  {#each allListings as listing}
    <div key={listing.id}>
      <Listing {...listing} />
    </div>
  {:else}
    {#each Array(10) as _, i}
      <div key={i} class="listing card loading" />
    {/each}
  {/each}

  {#if userLoggedIn}
    <div class="edit-listing-w">
      <EditListing id={null} {firebase} />
    </div>
  {/if}
</div>
