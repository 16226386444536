<script>
  import { getFormattedNumber, getFormattedTime } from "./utils/formatter";
  export let id = -1;
  export let title = "Ad title";
  export let price = "";
  export let images = [];
  export let imageThumbnails = [];
  export let description = "No description provided";
  export let phone = "-";
  export let location = "";
  export let tags = [];
  export let createdOn = new Date();

  let activeImageIndex = 0;

  const setActiveImageIndex = e => {
    activeImageIndex = +e.target.dataset.imageIndex;
  };

  const getListingType = content => {
    const wordContent = content.join(" ").toLowerCase();
    if (wordContent.indexOf("house") >= 0) return "home";
    if (wordContent.indexOf("phone") >= 0) return "headphones";
    if (wordContent.indexOf("headset") >= 0) return "headphones";
    if (wordContent.indexOf("bag") >= 0) return "briefcase";
    if (wordContent.indexOf("building") >= 0) return "home";
    if (wordContent.indexOf("yamaha mixer") >= 0) return "headphones";
    return "box";
  };

  const handleHashTagClick = e => {
    const { hashtag } = e.target.dataset;
    const hashtagChangeEvent = new CustomEvent("adeka-hashtag-change", {
      detail: { hashtag }
    });

    window.dispatchEvent(hashtagChangeEvent);
  };

  const handleImagePress = e => {
    const { url } = e.target.dataset;
    const imagePressEvent = new CustomEvent("adeka-image-press", {
      detail: { url }
    });
    window.dispatchEvent(imagePressEvent);
  };

  $: listingType = getListingType([title, description]);
</script>

<style type="text/scss">.listing {
  margin: 1rem auto 0; }

.title {
  font-size: 1.2rem;
  word-break: break-all; }

.price {
  color: #606569;
  font-weight: bold;
  font-size: 1.2rem; }
  .price::before {
    content: "Rs.";
    font-size: 0.7em;
    margin-right: 0.2em; }

.image--w {
  margin-bottom: 1rem; }

.image {
  background: center no-repeat rgba(132, 136, 140, 0.3);
  background-size: cover;
  height: 300px;
  width: calc(100% + 2rem);
  margin: 0 -1rem; }

.thumbnails {
  display: flex;
  flex-wrap: wrap; }
  .thumbnails img {
    opacity: 0.5;
    filter: grayscale(1);
    transition: opacity 0.15s; }
    .thumbnails img:hover, .thumbnails img.active {
      opacity: 1;
      filter: grayscale(0); }
    .thumbnails img:first-child {
      border-radius: 0 0 0 0.2rem; }
    .thumbnails img:last-child {
      border-radius: 0 0 0.2rem 0; }

.description {
  font-size: 1rem;
  word-break: break-all;
  white-space: pre-wrap; }

.tags {
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 0.3rem; }
  .tags span {
    padding: 0.3rem 0.15rem;
    cursor: pointer; }
    .tags span:first-child {
      margin-left: 0; }
    .tags span:before {
      content: "#"; }

.date-added {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.8rem; }

.phone {
  font-weight: bold;
  margin-top: 1rem;
  margin-right: 1rem;
  display: inline-block; }

.location {
  font-weight: bold;
  margin-top: 1rem;
  display: inline-block; }

@media (max-width: 420px) {
  .location {
    display: block;
    margin-left: 0; } }

.bg-decoration {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  font-size: 6rem;
  opacity: 0.2; }
</style>

<div class="listing card">
  <span class={`bg-decoration icon-${listingType}`} />
  <div class="title">{title}</div>
  <div class="price">{getFormattedNumber(price)}</div>
  <div class="date-added">
    <span class="icon-clock" />
    {getFormattedTime(new Date(createdOn))}
  </div>
  {#if images && images.length}
    <div class="image--w">
      <div
        class="image"
        on:mousedown={handleImagePress}
        data-url={images[activeImageIndex]}
        style={`background-image: url(${images[activeImageIndex]})`} />
      {#if imageThumbnails && imageThumbnails.length > 1}
        <div class="thumbnails">
          {#each imageThumbnails as thumbnail, index}
            <img
              class:active={index === activeImageIndex}
              src={thumbnail}
              alt="Preview of uploaded image"
              data-image-index={index}
              on:click={setActiveImageIndex} />
          {/each}
        </div>
      {/if}
    </div>
  {/if}
  <div class="description">
    {description}
    <div class="tags accent">
      {#each tags as tag}
        <span on:click={handleHashTagClick} data-hashtag={tag}>{tag}</span>
      {/each}
    </div>
  </div>
  <a href="tel:{phone}" class="phone accent">
    <span class="icon-phone" />
    {phone}
  </a>
  {#if location}
    <a
      target="blank"
      href={`https://www.google.lk/maps/place/${location}`}
      class="location accent">
      <span class="icon-compass" />
      {location}
    </a>
  {/if}
</div>
